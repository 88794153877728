import { FaCaretDown } from 'react-icons/fa'
import styled, { css } from 'styled-components/macro'

interface ItemRoleProps {
  isElder?: boolean
  isPioneer?: boolean
  isServant?: boolean
}

export const GroupItemRole = styled(FaCaretDown)<ItemRoleProps>`
  margin-left: 0.25em;
  margin-right: -0.25em;
  font-size: 1.25em;
  vertical-align: -.15em;
  transform-origin: center calc(50% + 1px);
  
  ${p => p.isElder && css`
    color: #4da5d8;
    transform: rotate(180deg);
  `}

  ${p => p.isPioneer && css`
    color: #3b9c4b;
    transform: rotate(270deg);
  `}

  ${p => p.isServant && css`
    color: #d84d4d;
    transform-origin-y: center;
    transform: rotate(90deg);
  `}
`
