import styled from 'styled-components/macro'

import { HeaderIcon, HeaderText, PageHeader } from '../../components/PageHeader/PageHeader'

export const LinksList = styled.ul`
  margin-bottom: -1.5rem;

  li {
    margin-bottom: 1.5rem;

    ${PageHeader} {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 0;
        background: #000;
        transition: .25s ease;
        pointer-events: none;
      }

      ${HeaderText}, ${HeaderIcon} {
        z-index: 11;
      }
    }
    a:hover {
      ${PageHeader}:after {
        opacity: 0.3;
      }
    }
  }
`
