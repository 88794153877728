import styled, { css } from 'styled-components/macro'

export const Container = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 2.25fr 1fr;
  column-gap: 0.25rem;
  padding: 0.125rem 0.25rem;
`

export const GroupsContainer = styled.div`
  display: grid;
  align-self: baseline;
  grid-template-columns: repeat(2, 1fr);
`

export const ExtraContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 0.5rem;

  > *:first-child {
    margin-top: 0;
    margin-bottom: auto;
  }
`

export const GroupBlock = styled.div`
  margin-bottom: 0.5rem;
`

export const GroupTitle = styled.div`
  margin-bottom: 0.25rem;
  font-size: 16px;
  font-weight: 500;
`

export const GroupList = styled.ol`
  counter-reset: group;
  list-style: none;
  font-size: 12px;
`

interface GroupListItemProps {
  isInner?: boolean
  isElder?: boolean
}

export const GroupListItem = styled.li<GroupListItemProps>`
  counter-increment: group;
  margin-bottom: 0.05rem;
  font-weight: 100;
  white-space: nowrap;

  ${p => p.isElder && css`
    color: #ed8739;
    font-weight: 400;
  `}

  &:before {
    content: counter(group) ' •';
    display: inline-block;
    width: 3.25ch;
    margin-right: 0.325rem;
    color: #ed8739;
    text-align: right;
    white-space: nowrap;

    ${p => p.isInner && css`
      margin-right: 0.75rem;
    `}
  }
`

export const UpdateDate = styled.div`
  font-size: 18px;
  font-weight: 100;
  text-align: right;
`
