import { isDate, parse } from 'date-fns'

import { getDateFromUTC } from './date'

export type Date2String<D> = {
  [K in keyof D]: D[K] extends Array<infer U>
    ? Array<Date2String<U>>
    : D[K] extends Date
      ? string
      : Date2String<D[K]>
}

type JSONObjectFromType<T> = T extends {}
  ? { [K in keyof T]: Exclude<T[K], Date> }
  : T extends Array<infer U>
    ? Array<JSONObjectFromType<U>>
    : T

export function mapApiResponse<T>(response: JSONObjectFromType<T>): T {
  if (response === null) return response as T

  if (Array.isArray(response))
    return response.map(mapApiResponse) as unknown as T

  if (typeof response === 'string' && isValidDate(response))
    return getDateFromUTC(response) as unknown as T

  if (['string', 'number', 'boolean'].includes(typeof response))
    return response as T

  return Object.entries(response as object).reduce((reduced, [key, value]) => ({
    ...reduced,
    [key]: mapApiResponse(value),
  }), {} as T)
}

function isValidDate(dateString: string) {
  try {
    if (!(/\d{4}-\d{2}-\d{2}.*/).test(dateString))
      return false

    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date())

    return isDate(parsedDate)
  } catch {
    return false
  }
}
