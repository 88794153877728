import styled, { css } from 'styled-components/macro'

interface BoxProps {
  fill?: boolean
  centered?: boolean
}

export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;

  ${p => p.fill && css`
    flex: 1;
  `}

  ${p => p.centered && css`
    height: 100%;
    max-height: 100vh;
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
`
