import { Link } from 'react-router-dom'

import { Box } from '../../components/Box/Box'
import { Page } from '../../components/Page/Page'
import { HeaderIcon, HeaderText, HeaderTitle, PageHeader } from '../../components/PageHeader/PageHeader'
import { ReactComponent as DesignacoesMecanicasIcon } from '../../icons/designacoes-mecanicas.svg'
import { ReactComponent as GruposIcon } from '../../icons/grupos.svg'
import { ReactComponent as ManutencaoCronogramaIcon } from '../../icons/manutencao-cronograma.svg'
import { ReactComponent as ManutencaoEquipesIcon } from '../../icons/manutencao-equipes.svg'
import { ReactComponent as ReuniaoPublicaIcon } from '../../icons/reuniao-publica.svg'
import { ReactComponent as ServicoMinisterialIcon } from '../../icons/servico-ministerial.svg'
import { ReactComponent as VidaEMinisterioCristaoIcon } from '../../icons/vida-e-ministerio-cristao.svg'
import { LinksList } from './styles'

export function Home() {
  return (
    <Page fill>
      <Box>
        <LinksList>
          <li>
            <Link to="/vida-e-ministerio-cristao">
              <PageHeader bg="/header/vida-ministerio.jpg">
                <HeaderIcon>
                  <VidaEMinisterioCristaoIcon />
                </HeaderIcon>
                <HeaderText>
                  <HeaderTitle>
                    Vida e <br />
                    Ministério Cristão
                  </HeaderTitle>
                </HeaderText>
              </PageHeader>
            </Link>
          </li>
          <li>
            <Link to="/reuniao-publica">
              <PageHeader bg="/header/reuniao-publica.jpg">
                <HeaderIcon mergeWithPaper>
                  <ReuniaoPublicaIcon />
                </HeaderIcon>
                <HeaderText>
                  <HeaderTitle>
                    Discurso Pública e<br />
                    A Sentinela
                  </HeaderTitle>
                </HeaderText>
              </PageHeader>
            </Link>
          </li>
          <li>
            <Link to="/servico-ministerial">
              <PageHeader bg="/header/servico-ministerial.jpg">
                <HeaderIcon>
                  <ServicoMinisterialIcon />
                </HeaderIcon>
                <HeaderText>
                  <HeaderTitle>Serviço Ministerial</HeaderTitle>
                </HeaderText>
              </PageHeader>
            </Link>
          </li>
          <li>
            <Link to="/grupos">
              <PageHeader bg="/header/grupos.jpg">
                <HeaderIcon>
                  <GruposIcon />
                </HeaderIcon>
                <HeaderText>
                  <HeaderTitle big>
                    <small>Divisão de</small><br />
                    Grupos
                  </HeaderTitle>
                </HeaderText>
              </PageHeader>
            </Link>
          </li>
          <li>
            <Link to="/designacoes-mecanicas">
              <PageHeader bg="/header/designacoes-mecanicas.jpg">
                <HeaderIcon>
                  <DesignacoesMecanicasIcon />
                </HeaderIcon>
                <HeaderText>
                  <HeaderTitle small>Designações Mecânicas</HeaderTitle>
                </HeaderText>
              </PageHeader>
            </Link>
          </li>
          <li>
            <Link to="/manutencao-equipes">
              <PageHeader bg="/header/manutencao-equipes.jpg">
                <HeaderIcon>
                  <ManutencaoEquipesIcon />
                </HeaderIcon>
                <HeaderText>
                  <HeaderTitle big>
                    <small>Equipes de</small><br />
                    Manutenção
                  </HeaderTitle>
                </HeaderText>
              </PageHeader>
            </Link>
          </li>
          <li>
            <Link to="/manutencao-cronograma">
              <PageHeader bg="/header/manutencao-cronograma.jpg">
                <HeaderIcon>
                  <ManutencaoCronogramaIcon />
                </HeaderIcon>
                <HeaderText>
                  <HeaderTitle big>
                    <small>Cronograma de</small><br />
                    Manutenção
                  </HeaderTitle>
                </HeaderText>
              </PageHeader>
            </Link>
          </li>
        </LinksList>
      </Box>
    </Page>
  )
}
