import React, { useMemo } from 'react'

import { capitalize, padStart } from 'lodash-es'

import { Box } from '../../components/Box/Box'
import { CleaningBox } from '../../components/CleaningBox/CleaningBox'
import { GenerateCleaning } from '../../components/GenerateCleaning'
import { GroupItemRole } from '../../components/GroupItemRole/GroupItemRole'
import { Page } from '../../components/Page/Page'
import { HeaderIcon, HeaderText, HeaderTitle, PageHeader } from '../../components/PageHeader/PageHeader'
import { Paper } from '../../components/Paper/Paper'
import { Statistics } from '../../components/Statistics/Statistics'
import jsonData from '../../data/grupos.json'
import { usePrintFilename } from '../../hooks/usePrintFilename'
import { ReactComponent as GruposIcon } from '../../icons/grupos.svg'
import { mapApiResponse } from '../../utils/api-helper'
import { dateFormat } from '../../utils/date'
import { Container, ExtraContainer, GroupBlock, GroupList, GroupListItem, GroupTitle, GroupsContainer, UpdateDate } from './styles'
import { Data, Group } from './types'

export function Grupos() {
  const data = useMemo(() => mapApiResponse<Data>(jsonData as any), [])

  usePrintFilename('grupos.pdf')

  return (
    <Page>
      <Box fill>
        <PageHeader bg="/header/grupos.jpg">
          <HeaderIcon>
            <GruposIcon />
          </HeaderIcon>
          <HeaderText>
            <HeaderTitle big>
              <small>Divisão de</small><br />
              Grupos
            </HeaderTitle>
          </HeaderText>
        </PageHeader>

        <Paper fill>
          <Container>
            <GroupsContainer>
              {data?.groups.map(group => (
                <GroupBlock key={group.number}>
                  <GroupTitle>
                    <span className="uppercase">Grupo {padStart(group.number.toString(), 2, '0')}</span> • {group.responsible}
                  </GroupTitle>

                  <GroupList>
                    {React.Children.toArray(group.participants.map(participant => (
                      <GroupListItem isInner={participant.isChild} isElder={participant.isElder}>
                        {participant.name}
                        {participant.isElder && <GroupItemRole isElder />}
                        {participant.isServant && <GroupItemRole isServant />}
                        {participant.isPioneer && <GroupItemRole isPioneer />}
                      </GroupListItem>
                    )))}
                  </GroupList>
                </GroupBlock>
              ))}
            </GroupsContainer>

            <ExtraContainer>
              {data && <CleaningBox data={data.cleaning} />}

              {data && (
                <Statistics
                  data={{
                    total: calculateRoleTotal(data.groups),
                    elders: calculateRoleTotal(data.groups, 'isElder'),
                    servants: calculateRoleTotal(data.groups, 'isServant'),
                    pioneers: calculateRoleTotal(data.groups, 'isPioneer'),
                  }}
                />
              )}

              {data && (
                <UpdateDate>
                  Atualizado em<br />
                  {dateFormat(data.updatedDate, 'dd \'de\' ')}
                  {capitalize(dateFormat(data.updatedDate, 'MMMM '))}
                  {dateFormat(data.updatedDate, '\'de\' yyyy')}
                </UpdateDate>
              )}
            </ExtraContainer>
          </Container>
        </Paper>
      </Box>
      {process.env.NODE_ENV === 'development' && <GenerateCleaning />}
    </Page>
  )
}

function calculateRoleTotal(groups: Group[], role?: 'isElder' | 'isServant' | 'isPioneer') {
  return groups.reduce((count, { participants }) => (
    role !== undefined
      ? participants.reduce((count, { [role]: chosenRole }) => (chosenRole ? count + 1 : count), count)
      : participants.length + count
  ), 0)
}
