import styled, { css } from 'styled-components/macro'

import { DottedElement, DottedSpare } from '../../components/DottedSpare/DotedSpare'
import { PaperTitle as BasePaperTitle } from '../../components/Paper/PaperTitle'

export const PaperTitle = styled(BasePaperTitle)`
  margin-bottom: 1.5rem;
`

interface SectionTitleProps {
  color: string
}

export const SectionTitle = styled.h4<SectionTitleProps>`
  display: flex;
  align-items: center;
  margin: 0 0 0.5rem;
  margin-left: -1rem;
  color: ${p => p.color};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

  &:before {
    content: '';
    display: block;
    width: 45px;
    height: 5px;
    margin-right: 0.75rem;
    background: ${p => p.color};
  }
`

export const BlocksContainer = styled.div`
  display: flex;
`

export const SpecialOcasion = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 340px;
  opacity: 0.4;
  font-size: 48px;
  font-weight: 700;
  text-align: center;
`

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 180px);
  padding-bottom: 0.75rem;
`

interface SectionBlockProps {
  narrow?: boolean
}

export const SectionBlock = styled.div<SectionBlockProps>`
  display: flex;
  flex-direction: column;
  gap: ${p => p.narrow ? 0.25 : 0.5}rem;
  margin-bottom: 1rem;
`

interface SectionItemProps {
  baseColor: string
}

export const SectionItem = styled.div<SectionItemProps>`
  --base-color: ${p => p.baseColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
`

export const ItemTime = styled.div`
  width: 3.5ch;
  margin-right: 0.5rem;
  color: var(--base-color);
  font-size: 15px;
  font-weight: 900;
  text-align: right;
  white-space: nowrap;
`

export const ItemValue = styled(DottedElement)`
  flex: 1 0;
  /* height: 18px; */
  margin-right: 0.15rem;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.125;

  ${DottedSpare} {
    background-position-y: 50%;
  }

  .content {
    position: relative;
    max-width: calc(100% - 7px);
    padding-right: 0.25rem;
  }

  small {
    display: block;
    position: absolute;
    margin-top: -0.05em;
    line-height: 1;
    font-weight: normal;
    font-style: italic;
    white-space: nowrap;
  }
`

export const ItemActor = styled.div`
  position: relative;
  white-space: nowrap;
  font-size: 14px;
  margin-left: 0.15rem;
  text-align: right;

  small {
    display: block;
    position: absolute;
    right: 0;
    line-height: 1;
  }
`

export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
  margin-left: auto;
  margin-top: -1.5rem;
  text-align: right;

  br {
    display: block;
    content: '';
    height: 0.5rem;
  }
`

interface RightBlockItemProps {
  nowrap?: boolean
}

export const RightBlockItem = styled.div<RightBlockItemProps>`
  margin: 0.2rem 0;
  font-size: 13px;
  line-height: 1.1;

  ${p => p.nowrap && css`
    white-space: nowrap;
    direction: rtl;
  `}

  strong {
    color: #2C514C;
    font-size: 12px;
  }
`
