import React, { useMemo } from 'react'

import ptBR from 'date-fns/locale/pt-BR'
import { capitalize } from 'lodash-es'

import { DotDivisor } from '../../components/DotDivisor/DotDivisor'
import { Paper } from '../../components/Paper/Paper'
import { dateFormat } from '../../utils/date'
import { BlocksContainer, ItemActor, ItemTime, ItemValue, LeftBlock, PaperTitle, RightBlock, RightBlockItem, SectionBlock, SectionItem, SectionTitle, SpecialOcasion } from './styles'
import { Week } from './types'

interface VidaEMinisterioWeekProps {
  week: Week
}

export function VidaEMinisterioWeek({ week }: VidaEMinisterioWeekProps) {
  const dateRangeText = useMemo(() => {
    if (!week) return ''

    if (week.fromDate.getMonth() !== week.toDate.getMonth()) {
      return [
        week.fromDate,
        week.toDate,
      ].map(date => [
        dateFormat(date, 'dd \'de\'', { locale: ptBR }),
        capitalize(dateFormat(date, 'MMMM', { locale: ptBR })),
      ].join(' ')).join(' - ')
    }

    return [
      [dateFormat(week.fromDate, 'dd'), dateFormat(week.toDate, 'dd')].join('-'),
      'de',
      capitalize(dateFormat(week.toDate, 'MMMM', { locale: ptBR })),
    ].join(' ')
  }, [week?.fromDate, week?.toDate])

  const narrowLines = (week.school?.length ?? 0) > 3 && (week.christianLife?.items.length ?? 0) > 1

  return (
    <Paper midMargin>
      <PaperTitle color="#2C514C">
        {dateRangeText}
        {week.weekRead && (
          <>
            <DotDivisor />
            <span className="uppercase">{week.weekRead}</span>
          </>
        )}
      </PaperTitle>

      <BlocksContainer>
        {week.specialOcasion
          ? <SpecialOcasion>{week.specialOcasion}</SpecialOcasion>
          : (
            <>
              <LeftBlock>
                <SectionTitle color="#2C514C">Tesouros da Palavra de Deus</SectionTitle>

                <SectionBlock narrow={narrowLines}>
                  {React.Children.toArray(week.treasures.map(item => (
                    <SectionItem baseColor="#2C514C">
                      <ItemTime>{item.time}m</ItemTime>
                      <ItemValue>{item.value}</ItemValue>
                      <ItemActor>{item.actor}</ItemActor>
                    </SectionItem>
                  )))}
                </SectionBlock>

                <SectionTitle color="#C18626">Faça seu Melhor no Ministério</SectionTitle>

                <SectionBlock narrow={narrowLines}>
                  {React.Children.toArray(week.school?.map(item => (
                    <SectionItem baseColor="#C18626">
                      <ItemTime>{item.time}m</ItemTime>
                      <ItemValue>{item.value}</ItemValue>
                      <ItemActor>{item.actor}</ItemActor>
                    </SectionItem>
                  )))}
                </SectionBlock>

                <SectionTitle color="#951426">Nossa Vida Cristã</SectionTitle>

                <SectionBlock narrow={narrowLines}>
                  {React.Children.toArray(week.christianLife?.items.map(item => (
                    <SectionItem baseColor="#951426">
                      <ItemTime>
                        {item.time !== null ? (
                          <>
                            {item.time}m
                          </>
                        ) : <small>•••</small>}
                      </ItemTime>
                      <ItemValue>{item.value}</ItemValue>
                      <ItemActor>
                        {item.actor}
                        {item.actorInfo && <small>{item.actorInfo}</small>}
                      </ItemActor>
                    </SectionItem>
                  )))}
                  {week.christianLife?.bibleStudy && (
                    <SectionItem baseColor="#951426">
                      <ItemTime>{week.christianLife.bibleStudy.time ?? 30}m</ItemTime>
                      <ItemValue>
                        Estudo bíblico de congregação<br />
                        <small>{week.christianLife.bibleStudy.bookAndChapter}</small>
                      </ItemValue>
                      <ItemActor>{week.christianLife.bibleStudy.actor}</ItemActor>
                    </SectionItem>
                  )}
                </SectionBlock>
              </LeftBlock>

              <RightBlock>
                <div>
                  <RightBlockItem>
                    <strong>Presidente</strong>
                    <DotDivisor />
                    <span>{week.president}</span>
                  </RightBlockItem>
                  <RightBlockItem>
                    <strong>Oração Inicial</strong>
                    <DotDivisor />
                    <span>{week.initialPrayer}</span>
                  </RightBlockItem>
                </div>

                <div>
                  {week.christianLife?.bibleStudy && (
                    <RightBlockItem style={{ height: 15 }}>
                      <strong>Leitor</strong>
                      <DotDivisor />
                      {week.christianLife.bibleStudy.reader
                        ? <span>{week.christianLife.bibleStudy.reader}</span>
                        : <em>Sem leitura</em>}
                    </RightBlockItem>
                  )}

                  <br />

                  <RightBlockItem nowrap>
                    <strong>Oração Final</strong>
                    <DotDivisor />
                    <span>{week.finalPrayer}</span>
                  </RightBlockItem>
                </div>
              </RightBlock>
            </>
          )}
      </BlocksContainer>
    </Paper>
  )
}
