import { addMinutes, format, getDaysInMonth } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { capitalize, padStart } from 'lodash-es'

export function getDateFromUTC(date: string) {
  return addMinutes(new Date(date), new Date().getTimezoneOffset())
}

export const dateFormat: typeof format = (date, formatString, options) => {
  if (!options)
    options = {}

  if (!options?.locale)
    options.locale = ptBR

  return format(date, formatString, options)
}

export function formatYearMonth(yearMonth: string): [year: string, month: string] {
  if (!yearMonth) return ['-', '-']

  const [year, monthNumber] = yearMonth.split('-').map(Number)

  const month = capitalize(new Date(year, monthNumber - 1, 1).toLocaleDateString('pt-BR', { month: 'long' }))

  return [String(year), month]
}

export function getYearMonthFromDateRange(startDate: Date, endDate: Date): string {
  function getYearMonthString(date: Date) {
    return `${date.getFullYear()}-${padStart(String(date.getMonth() + 1), 2, '0')}`
  }

  const sameMonth = startDate.getMonth() === endDate.getMonth() ? startDate : null

  if (sameMonth)
    return getYearMonthString(sameMonth)

  const startMonth = new YearMonth(startDate)
  const endMonth = new YearMonth(endDate, true)
  return getYearMonthString(startMonth.getYearMonthIntersection(endMonth).date)
}

export class YearMonth {
  protected get month() {
    return this.date.getMonth()
  }

  protected get amountOfDays() {
    return this.isEnd
      ? this.date.getDate()
      : getDaysInMonth(this.date) - this.date.getDate() + 1
  }

  constructor(public readonly date: Date, protected readonly isEnd = false) {}

  getYearMonthIntersection(compare: YearMonth): YearMonth {
    if (this.amountOfDays >= compare.amountOfDays)
      return this
    return compare
  }
}

export class YearMonthArray<T extends { fromDate: Date, toDate: Date }> extends Array<T> {
  get yearMonth() {
    const startDate = this[0].fromDate
    const endDate = [...this].pop()!.toDate
    return formatYearMonth(getYearMonthFromDateRange(startDate, endDate))
  }

  get year() {
    return this.yearMonth[0]
  }

  get month() {
    return this.yearMonth[1]
  }
}
