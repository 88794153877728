import styled from 'styled-components/macro'

export const DesignacoesTable = styled.table`
  margin-top: 1rem;
  text-align: center;
`
DesignacoesTable.defaultProps = {
  cellSpacing: 0,
  cellPadding: 0,
}

export const RolesRow = styled(RolesRowComonent)`

`

function RolesRowComonent({ children, ...props }: JSX.IntrinsicElements['tr']) {
  return (
    <thead>
      <tr {...props}>
        {children}
      </tr>
    </thead>
  )
}

export const Role = styled.th`
  padding-bottom: 1rem;
  color: #000;
  font-size: 18px;
  font-weight: 700;
`

export const DatesContainer = styled.tbody`
  
`

export const DateRow = styled.tr`
  &:nth-child(odd) ${_ => DateCell} {
    background: #f3f3f3;
  }
`

const DateCell = styled.td`
  padding: 1.125rem 0.05rem;
  line-height: 1.25;
`

interface DateProps {
  weekend?: boolean
}

export const Date = styled(DateCell)<DateProps>`
  padding-right: 0.5rem;
  font-size: 13px;
  font-weight: 700;
  text-align: right;
  white-space: nowrap;

  color: ${p => p.weekend ? '#ed8739' : '#2a9f8f'};

  small {
    font-size: 14px;
  }
`

export const Actors = styled(DateCell)`
  color: #000;
  font-size: 13px;
  font-weight: 600;
`

export const SpecialOcasion = styled(DateCell)`
  padding: 0;
  font-size: 1.725em;
  font-weight: 700;
  opacity: 0.6;
`

export const Info = styled.div`
  margin-top: auto;
  margin-bottom: 1rem;
  color: #000;
  font-size: 15px;

  strong {
    font-weight: 600;
  }
`

export const InfoTitle = styled.h3`
  display: inline-block;
  margin: 0;
  color: #951426;
  font-size: inherit;
  font-weight: 700;
  text-transform: uppercase;

  svg {
    vertical-align: -.125em;
    font-size: 1.05em;
  }
`
