import styled, { css } from 'styled-components/macro'

interface PaperProps {
  fill?: boolean
  bgColor?: string
  stretch?: boolean
  smallMargin?: boolean
  midMargin?: boolean
}

const ANGLE_CUT_HEIGHT = 15 // px

export const Paper = styled(PaperComponent)<PaperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 12px 15px;
  filter: drop-shadow(0px 1px 8px rgba(0,0,0,0.3));

  ${p => p.fill && css`
    flex: 1 0;
    padding-bottom: 0;
  `}

  ${p => p.stretch && css`
    margin-left: -10px;
    margin-right: -10px;
  `}

  &:nth-last-child(n + 2) {
    margin-bottom: 1.5rem;

    ${p => p.smallMargin && css`
      margin-bottom: 0.275rem;
    `}

    ${p => p.midMargin && css`
      margin-bottom: 1rem;
    `}
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: ${ANGLE_CUT_HEIGHT}px;
    left: 0;
    width: 100%;
    height: calc(100% - ${ANGLE_CUT_HEIGHT}px);
    z-index: -1;
    background: ${p => p.bgColor ?? '#fff'};
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 15px;
    z-index: -1;
    clip-path: url(#paperCutPath); // paper cut
    background: ${p => p.bgColor ?? '#fff'};
  }
`

export function PaperComponent({ children, ...props }: JSX.IntrinsicElements['div'] & PaperProps) {
  return (
    <div {...props}>
      <PaperCutSVG />
      {children}
    </div>
  )
}

export function PaperCutSVG() {
  return (
    <svg viewBox="0 0 746 15" width="0" height="0" style={{ position: 'absolute' }}>
      <defs>
        <clipPath id="paperCutPath" clipPathUnits="objectBoundingBox">
          <path d="M0,0 L0,1.1 L1.1,1.1 L1.1,0 L0.95,0 L0.92,1 L0.89,0 Z" />
        </clipPath>
      </defs>
    </svg>
  )
}
