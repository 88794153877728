import styled from 'styled-components/macro'

export const DottedSpare = styled.span`
  flex: 0 0 12px;
  background: radial-gradient(currentColor 28%, transparent 28%) repeat-x;
  background-position: 0 0.5em;
  background-size: 7px 7px;
`

export const DottedElement = styled(DottedElementComponent)`
  --inner-bg: var(--bg, #fff);

  ${DottedSpare} {
    display: block;
    background-position-x: 3px;
  }

  .content {
    display: inline-block;
    padding-right: 0.15rem;
    background: var(--inner-bg);
  }
`

function DottedElementComponent({ children, ...props }: JSX.IntrinsicElements['div']) {
  return (
    <div {...props}>
      <DottedSpare>
        <span className="content">{children}</span>
      </DottedSpare>
    </div>
  )
}
