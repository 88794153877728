import { capitalize } from 'lodash-es'

import { DotDivisor } from '../../components/DotDivisor/DotDivisor'
import { Paper } from '../../components/Paper/Paper'
import { PaperTitle } from '../../components/Paper/PaperTitle'
import { dateFormat, getYearMonthFromDateRange } from '../../utils/date'
import { AlignedItems, BlocksRow, ItemRole, LeftBlock, LineItem, LineValue, RightBlock, SpecialOcasion, SubjectText, WatchtowerLabel } from './styles'
import { Week } from './types'

interface ReuniaoPublicaWeekProps {
  week: Week
  mainYearMonth: string
}

export function ReuniaoPublicaWeek({ week, mainYearMonth }: ReuniaoPublicaWeekProps) {
  const weekYearMonth = getYearMonthFromDateRange(week.date, week.date)

  return (
    <Paper>
      <PaperTitle color="#951426">
        {capitalize(dateFormat(week.date, 'EEEE dd'))}
        {mainYearMonth !== weekYearMonth && (
          ` de ${capitalize(dateFormat(week.date, 'MMMM'))}`
        )}
      </PaperTitle>

      <BlocksRow>
        <LeftBlock>
          {week.speech && <SubjectText bold={!!week.specialOcasion}>{week.speech.subject || '-'}</SubjectText>}

          <AlignedItems>
            {week.president && (
              <LineItem>
                <ItemRole>Presidente</ItemRole>
                <DotDivisor />
                <LineValue>{week.president}</LineValue>
              </LineItem>
            )}
            {week.speech && (
              <>
                {week.speech.actor && (
                  <LineItem>
                    <ItemRole>Orador</ItemRole>
                    <DotDivisor />
                    <LineValue>
                      {week.speech.actor}
                      {week.speech.congregation && <small>{week.speech.congregation}</small>}
                    </LineValue>
                  </LineItem>
                )}
                {week.speech.sent !== false && (
                  <LineItem>
                    <ItemRole>Enviado</ItemRole>
                    <DotDivisor />
                    <LineValue>{week.speech.sent || <span className="empty">---</span>}</LineValue>
                  </LineItem>
                )}
                {week.speech.extra?.map(extra => (
                  <LineItem>
                    <ItemRole dangerouslySetInnerHTML={{ __html: extra.label.split('\n').join('<br/>') }} />
                    <DotDivisor />
                    <LineValue>{extra.value}</LineValue>
                  </LineItem>
                ))}
              </>
            )}
          </AlignedItems>
        </LeftBlock>
        <RightBlock>
          <WatchtowerLabel>
            {week.watchtower && 'A Sentinela'}
          </WatchtowerLabel>

          {week.watchtower && <SubjectText>{week.watchtower.subject}</SubjectText>}

          <AlignedItems rtl>
            {week.watchtower && (
              <>
                <LineItem>
                  <LineValue>{week.watchtower.actor}</LineValue>
                  <DotDivisor />
                  <ItemRole>Dirigente</ItemRole>
                </LineItem>
                <LineItem>
                  <LineValue>
                    {week.watchtower.reader ?? <em className="empty">Sem leitura</em>}
                    <small>&nbsp;</small>
                  </LineValue>
                  <DotDivisor />
                  <ItemRole>Leitor</ItemRole>
                </LineItem>
              </>
            )}
            {week.finalPrayer && (
              <LineItem spaced={!week.watchtower ? 1.35 : undefined}>
                <LineValue>{week.finalPrayer}</LineValue>
                <DotDivisor />
                <ItemRole>Oração Final {week.specialOcasion && <><br />Sentinela</>}</ItemRole>
              </LineItem>
            )}
          </AlignedItems>
        </RightBlock>

        {week.specialOcasion && (
          <SpecialOcasion>
            {week.specialOcasion}
          </SpecialOcasion>
        )}
      </BlocksRow>
    </Paper>
  )
}
