import styled from 'styled-components/macro'

import { PaperTitle } from '../../components/Paper/PaperTitle'

export const Container = styled.div`
  flex: 1;
  font-size: 0.9em;
  margin-top: 0.25em;
`

export const TeamsContainer = styled.div`
  display: grid;
  align-self: baseline;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.5rem;
`

export const TeamBlock = styled.div`
  margin-bottom: 0.75rem;
`

export const TeamIdentifier = styled.strong`
  display: block;
  opacity: 0.8;
  margin-bottom: 0.25em;
  font-weight: 600;
  text-align: center;
`

export const TeamTitle = styled(PaperTitle)`
  margin-bottom: 0.25em;
  padding-bottom: 0.25em;
  border-bottom: 1px solid #ccc;
  font-size: 1em;
  line-height: 1.1;
`

export const TeamFrequency = styled.span`
  color: #555;
  font-size: 0.9em;
  font-weight: 600;
  vertical-align: 0.05em;
  text-align: right;
`

export const TeamList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 0.25em;
  align-items: center;
`

export const TeamItemName = styled.strong`
  font-size: 0.8em;
  font-weight: 600;
`

export const TeamItemCongregation = styled.span`
  font-size: 0.8em;
  text-align: right;
`

export const ExtraTitle = styled(TeamTitle)`
  justify-content: center;
  text-align: center;
`

export const ExtraItemName = styled(TeamItemName)`
  grid-column: 1 / 3;
  text-align: center;
`
