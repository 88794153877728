import { Fragment, useMemo } from 'react'

import { format as formatDate, monthsInYear, nextDay } from 'date-fns'
import ptBrLocale from 'date-fns/locale/pt-BR'

import { Box } from '../../components/Box/Box'
import { Page } from '../../components/Page/Page'
import { HeaderIcon, HeaderText, HeaderTitle, PageHeader } from '../../components/PageHeader/PageHeader'
import { Paper } from '../../components/Paper/Paper'
import jsonData from '../../data/manutencao-cronograma.json'
import { usePrintFilename } from '../../hooks/usePrintFilename'
import { ReactComponent as ManutencaoCronogramaIcon } from '../../icons/manutencao-cronograma.svg'
import { mapApiResponse } from '../../utils/api-helper'
import { Container, GroupHeaderItem, GroupItem, GroupItemDate, GroupItemDot, GroupItemFrequency, GroupItemTeam, GroupName, GroupTable } from './styles'
import { Data, Group } from './types'

export function ManutencaoCronograma() {
  const data = useMemo(() => mapApiResponse<Data>(jsonData as any), [])

  usePrintFilename('manutencao-equipes.pdf')

  const months = useMemo(() => {
    const today = new Date()
    return Array(monthsInYear).fill(null).map((_, monthIndex) => {
      const date = new Date(`${today.getFullYear()}-${monthIndex + 1}-05`)
      const name = formatDate(date, 'MMM', {
        locale: ptBrLocale,
      })
      return { name, number: monthIndex + 1 }
    })
  }, [])

  const groups = useMemo<Group[]>(() => {
    if (!data) return []
    const today = new Date()

    return [
      ...data.groups,
      {
        name: 'Limpeza Trimestral',
        items: [
          {
            name: 'Limpeza Completa no Salão do Reino',
            team: 'todas',
            frequency: 'Trimestral',
            months: data.generalCleaning.months.map(month => {
              let date = new Date(`${today.getFullYear()}-${month}-01`)
              const weeksToAdd = date.getDay() === data.generalCleaning.dayOfWeek
                ? data.generalCleaning.weekOfMonth - 1
                : data.generalCleaning.weekOfMonth
              for (let i = 1; i <= weeksToAdd; i++)
                date = nextDay(date, data.generalCleaning.dayOfWeek)
              const day = date.getDate()

              return [month, day] as [number, number]
            }),
          },
        ],
      },
    ]
  }, [data])

  return (
    <Page>
      <Box fill>
        <PageHeader bg="/header/manutencao-cronograma.jpg" smaller>
          <HeaderIcon>
            <ManutencaoCronogramaIcon />
          </HeaderIcon>
          <HeaderText>
            <HeaderTitle>
              <small>Cronograma de</small><br />
              Manutenção
              <br /><br />
            </HeaderTitle>
          </HeaderText>
        </PageHeader>

        {groups.map((group, i) => (
          <Paper key={i} stretch smallMargin>
            <Container>
              <GroupName>{group.name}</GroupName>

              <GroupTable>
                <GroupHeaderItem>Objeto da Manutenção</GroupHeaderItem>
                <GroupHeaderItem upper>Equipe</GroupHeaderItem>
                <GroupHeaderItem upper>Frequência</GroupHeaderItem>
                {months.map(month => (
                  <GroupHeaderItem key={month.number} upper>{month.name}</GroupHeaderItem>
                ))}

                {group.items.map((item, i) => (
                  <Fragment key={i}>
                    <GroupItem>{item.name}</GroupItem>
                    <GroupItemTeam>
                      {typeof item.team === 'number'
                        ? <>E{String(item.team).padStart(2, '0')}</>
                        : item.team}
                    </GroupItemTeam>
                    <GroupItemFrequency>{item.frequency}</GroupItemFrequency>
                    {months.map(month => {
                      const simpleMonths = item.months as number[]
                      const monthsWithDay = item.months as [number, number][]
                      return (
                        <GroupItem key={month.number}>
                          {typeof item.months[0] === 'number'
                            ? simpleMonths.includes(month.number) && <GroupItemDot color={getColorFromString(item.name)} />
                            : monthsWithDay.map(([month]) => month).includes(month.number) && (
                              <GroupItemDate>{monthsWithDay.find(item => item[0] === month.number)?.[1]}</GroupItemDate>
                            )}
                        </GroupItem>
                      )
                    })}
                  </Fragment>
                ))}
              </GroupTable>
            </Container>
          </Paper>
        ))}
      </Box>
    </Page>
  )
}

function getColorFromString(str: string) {
  function hashCode(str: string) {
    let hash = 0
    for (let i = 0; i < str.length; i++)
      // eslint-disable-next-line no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash)

    return hash
  }

  // eslint-disable-next-line no-bitwise
  const hexValue = (hashCode(str) & 0x00FFFFFF)
    .toString(16)
    .toUpperCase()

  return `#${'00000'.substring(0, 6 - hexValue.length)}${hexValue}`
}
