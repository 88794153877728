import styled from 'styled-components/macro'

interface PaperTitleProps {
  color?: string
}

export const PaperTitle = styled.h3<PaperTitleProps>`
  margin: 0 0 0.5rem;
  color: ${p => p.color ?? 'inherit'};
  font-size: 17px;
  font-weight: bold;

  small {
    font-weight: 500;
  }
`
