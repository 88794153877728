import { useEffect } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'

import { Box } from './components/Box/Box'
import { Page } from './components/Page/Page'
import { DesignacoesMecanicas } from './pages/DesignacoesMecanicas/DesignacoesMecanicas'
import { Grupos } from './pages/Grupos/Grupos'
import { Home } from './pages/Home/Home'
import { ManutencaoCronograma } from './pages/ManutencaoCronograma/ManutencaoCronograma'
import { ManutencaoEquipes } from './pages/ManutencaoEquipes/ManutencaoEquipes'
import { ReuniaoPublica } from './pages/ReuniaoPublica/ReuniaoPublica'
import { ServicoMinisterial } from './pages/ServicoMinisterial/ServicoMinisterial'
import { VidaEMinisterioCristao } from './pages/VidaEMinisterioCristao/VidaEMinisterioCristao'

export function App() {
  const { pathname: currentRoute } = useLocation()

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      setTimeout(() => window.scrollTo(0, 0))
    }
  }, [currentRoute])

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/vida-e-ministerio-cristao" element={<VidaEMinisterioCristao />} />
      <Route path="/reuniao-publica" element={<ReuniaoPublica />} />
      <Route path="/grupos" element={<Grupos />} />
      <Route path="/designacoes-mecanicas" element={<DesignacoesMecanicas />} />
      <Route path="/servico-ministerial" element={<ServicoMinisterial />} />
      <Route path="/manutencao-equipes" element={<ManutencaoEquipes />} />
      <Route path="/manutencao-cronograma" element={<ManutencaoCronograma />} />
      <Route
        path="/*"
        element={(
          <Page>
            <Box centered>
              <h2>Nothing here yet.</h2>
              <div style={{ fontSize: '60px' }}>😢</div>
              <h3>
                <Link to="/">
                  Go Back<br />
                  👈
                </Link>
              </h3>
            </Box>
          </Page>
        )}
      />
    </Routes>
  )
}
