import styled, { css } from 'styled-components/macro'

export const Container = styled.div`
`

export const CleaningTitle = styled.h3`
  margin: 0 0 0.5rem;
  font-size: 16px;
  font-weight: 500;
  text-align: right;

  svg {
    margin-right: 0.5rem;
    font-size: 30px;
  }
`

export const CleaningTable = styled.table`
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  box-shadow: 0 0 5px rgba(0,0,0,0.05);
`

CleaningTable.defaultProps = {
  cellPadding: 0,
  cellSpacing: 0,
}

export const CleaningTableHeader = styled(CleaningTableHeaderComponent)`
  padding: 2px 5px 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: right;
  background-color: #ed8739;
`

function CleaningTableHeaderComponent({ children, ...props }: Omit<JSX.IntrinsicElements['th'], 'colSpan'>) {
  return (
    <thead>
      <tr>
        <th {...props} colSpan={2}>{children}</th>
      </tr>
    </thead>
  )
}

export const CleaningTableMonth = styled.tbody``

interface CleaningTableRowProps {
  weekend?: boolean
  general?: boolean
}

export const CleaningTableDay = styled.tr<CleaningTableRowProps>`
  font-size: 12px;

  ${p => p.weekend && css`
    background: #dbdbdb;
  `}

  ${p => p.general && css`
    background: #eeeeee;
  `}
`

const CleaningTableRowItem = styled.td`
  padding: 5px 5px 4px;
`

export const CleaningTableDate = styled(CleaningTableRowItem)`
  padding-right: 0;
  color: #ed8739;
  font-weight: 900;
  white-space: nowrap;
  text-transform: capitalize;
`

export const CleaningTableGroupName = styled(CleaningTableRowItem)`
  padding-left: 0;
  font-weight: 700;
  text-align: right;
  white-space: nowrap;
`
