import styled, { css } from 'styled-components/macro'

interface PageProps {
  fill?: boolean
}

export const Page = styled.div<PageProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 210mm;
  height: 297mm;
  overflow: hidden;
  z-index: 1;
  margin: 0 auto;
  padding: 0 1.5rem;
  background: #fff;
  box-shadow: 0 0 15px rgba(0,0,0,0.3);

  @media not print {
    &:nth-last-child(n + 2) {
      margin-bottom: 2rem;
    }

    @media (prefers-color-scheme: dark) {
      box-shadow: 0 0 15px rgba(200,200,200,0.3);
    }
  }

  @media print {
    width: 210mm;
    height: 297mm;
  }

  ${p => p.fill && css`
    height: auto;
  `}
`
