import { Children, useMemo } from 'react'

import { groupBy, sortBy } from 'lodash-es'

import { Page } from '../../components/Page/Page'
import jsonData from '../../data/designacoes-mecanicas.json'
import { usePrintFilename } from '../../hooks/usePrintFilename'
import { mapApiResponse } from '../../utils/api-helper'
import { Month } from './Month'
import { Data } from './types'
import { getOverrideableMonth } from './utils'

export function DesignacoesMecanicas() {
  const data = useMemo(() => mapApiResponse<Data>(jsonData as any), [])

  const months = useMemo(() => (
    groupBy(sortBy(
      data?.weeks ?? [],
      getOverrideableMonth,
    ), getOverrideableMonth)
  ), [data?.weeks])

  usePrintFilename('designacoes-mecanicas.pdf')

  if (!data.weeks)
    return <Page />

  return (
    <>
      {Children.toArray(Object.values(months).map(weeks => (
        <Page>
          <Month weeks={weeks} />
        </Page>
      )))}
    </>
  )
}
