import { Children, useMemo } from 'react'

import { groupBy, sortBy } from 'lodash-es'

import { Page } from '../../components/Page/Page'
import jsonData from '../../data/reuniao-publica.json'
import { usePrintFilename } from '../../hooks/usePrintFilename'
import { mapApiResponse } from '../../utils/api-helper'
import { dateFormat } from '../../utils/date'
import { Month } from './Month'
import { Data } from './types'

export function ReuniaoPublica() {
  const data = useMemo(() => mapApiResponse<Data>(jsonData as any), [])

  const months = useMemo(() => (
    groupBy(sortBy(
      data?.weeks ?? [],
      week => dateFormat(week.date, 'yyyy-MM'),
    ), week => dateFormat(week.date, 'yyyy-MM'))
  ), [data?.weeks])

  usePrintFilename('reuniao-publica.pdf')

  if (!data?.weeks)
    return <Page />

  return (
    <>
      {Children.toArray(Object.values(months).map(weeks => (
        <Page>
          <Month weeks={weeks} />
        </Page>
      )))}
    </>
  )
}
