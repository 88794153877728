import styled, { css } from 'styled-components/macro'

const MAIN_COLOR = '#045a6c'

export const Container = styled.div`
  flex: 1;
  margin: 0 -15px -12px;
`

export const GroupName = styled.div`
  padding: 0.5em;
  padding-top: 0;
  padding-bottom: 0.25em;
  color: ${MAIN_COLOR};
  font-size: 1.125em;
  font-weight: 800;
  letter-spacing: -0.5px;
  line-height: 1;
  text-transform: uppercase;
`

export const GroupTable = styled.div`
  display: grid;
  grid-template-columns: 5fr 1.75fr 2.5fr repeat(12, 1fr);
  gap: 1px;
  font-size: 0.825em;
  background: rgba(0,0,0,0.125);
`

export const GroupHeaderItem = styled.div<{upper?: boolean}>`
  display: flex;
  align-items: center;
  padding: 0.5em 0.5em;
  font-weight: 700;
  line-height: 1.15;
  color: #fff;
  font-size: 0.925em;
  background: ${MAIN_COLOR};

  &:nth-child(n + 2) {
    justify-content: center;
  }

  ${p => p.upper && css`
    text-transform: uppercase;
  `}
`

export const GroupItem = styled.div`
  display: flex;
  align-items: center;
  min-height: 2.25em;
  padding: 0.425em 0.5em;
  color: #000;
  font-size: 0.925em;
  line-height: 1.125;
  background: #fff;
`

export const GroupItemTeam = styled(GroupItem)`
  justify-content: center;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
`

export const GroupItemFrequency = styled(GroupItem)`
  justify-content: center;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1;
`

export const GroupItemDot = styled.div<{color: string}>`
  font-size: 14px;
  width: 1em;
  height: 1em;
  margin: auto;
  border-radius: 50%;
  background: ${p => p.color};
`

export const GroupItemDate = styled.div`
  margin: auto;
  color: #8d0000;
  font-size: 1.5em;
  font-weight: 800;
`
