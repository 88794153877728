import { useMemo } from 'react'

import { chunk } from 'lodash-es'

import { Box } from '../../components/Box/Box'
import { Page } from '../../components/Page/Page'
import { HeaderDate, HeaderIcon, HeaderText, HeaderTitle, HeaderYear, PageHeader } from '../../components/PageHeader/PageHeader'
import jsonData from '../../data/vida-e-ministerio-cristao.json'
import { usePrintFilename } from '../../hooks/usePrintFilename'
import { ReactComponent as VidaEMinisterioCristaoIcon } from '../../icons/vida-e-ministerio-cristao.svg'
import { mapApiResponse } from '../../utils/api-helper'
import { YearMonthArray } from '../../utils/date'
import { Data } from './types'
import { VidaEMinisterioWeek } from './Week'

export function VidaEMinisterioCristao() {
  const data = useMemo(() => mapApiResponse<Data>(jsonData as any), [])

  const weeksPairs = useMemo(() => {
    if (!data?.weeks) return []

    return chunk(data.weeks, 2)
      .map(weeks => new YearMonthArray(...weeks))
  }, [data?.weeks])

  usePrintFilename('vida-e-ministerio-cristao.pdf')

  return (
    <>
      {weeksPairs.map((weeks, i) => (
        <Page key={i}>
          <Box>
            <PageHeader bg="/header/vida-ministerio.jpg">
              <HeaderIcon>
                <VidaEMinisterioCristaoIcon />
              </HeaderIcon>
              <HeaderText>
                <HeaderTitle>
                  Vida e <br />
                  Ministério Cristão
                </HeaderTitle>
                <HeaderDate>
                  {weeks.month}<br />
                  <HeaderYear>{weeks.year}</HeaderYear>
                </HeaderDate>
              </HeaderText>
            </PageHeader>

            {weeks.map(week => (
              <VidaEMinisterioWeek key={[week.fromDate, week.toDate].join('-')} week={week} />
            ))}
          </Box>
        </Page>
      ))}
    </>
  )
}
