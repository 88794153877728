import { padStart } from 'lodash-es'

import { GroupItemRole } from '../GroupItemRole/GroupItemRole'
import { Container, ItemLabel, ItemRow, ItemValue } from './styles'
import { StatisticsData } from './types'

interface StatisticsProps {
  data: StatisticsData
}

export function Statistics({ data }: StatisticsProps) {
  return (
    <Container>
      <ItemRow>
        <ItemLabel>Publicadores</ItemLabel>
        <ItemValue>
          {padStart(data.total.toString(), 2, '0')}
        </ItemValue>
      </ItemRow>
      <ItemRow>
        <ItemLabel>Anciãos</ItemLabel>
        <ItemValue>
          <GroupItemRole isElder />
          {padStart(data.elders.toString(), 2, '0')}
        </ItemValue>
      </ItemRow>
      <ItemRow>
        <ItemLabel>Servos Ministeriais</ItemLabel>
        <ItemValue>
          <GroupItemRole isServant />
          {padStart(data.servants.toString(), 2, '0')}
        </ItemValue>
      </ItemRow>
      <ItemRow>
        <ItemLabel>Pioneiros</ItemLabel>
        <ItemValue>
          <GroupItemRole isPioneer />
          {padStart(data.pioneers.toString(), 2, '0')}
        </ItemValue>
      </ItemRow>
    </Container>
  )
}
