import { Fragment, useMemo } from 'react'

import { Box } from '../../components/Box/Box'
import { Page } from '../../components/Page/Page'
import { HeaderIcon, HeaderText, HeaderTitle, PageHeader } from '../../components/PageHeader/PageHeader'
import { Paper } from '../../components/Paper/Paper'
import jsonData from '../../data/manutencao-equipes.json'
import { usePrintFilename } from '../../hooks/usePrintFilename'
import { ReactComponent as ManutencaoEquipesIcon } from '../../icons/manutencao-equipes.svg'
import { mapApiResponse } from '../../utils/api-helper'
import { Container, ExtraItemName, ExtraTitle, TeamBlock, TeamFrequency, TeamIdentifier, TeamItemCongregation, TeamItemName, TeamList, TeamTitle, TeamsContainer } from './styles'
import { Data } from './types'

export function ManutencaoEquipes() {
  const data = useMemo(() => mapApiResponse<Data>(jsonData as any), [])

  usePrintFilename('manutencao-equipes.pdf')

  return (
    <Page>
      <Box fill>
        <PageHeader bg="/header/manutencao-equipes.jpg" smaller>
          <HeaderIcon>
            <ManutencaoEquipesIcon />
          </HeaderIcon>
          <HeaderText>
            <HeaderTitle>
              <small>Equipes de</small><br />
              Manutenção
              <br /><br />
            </HeaderTitle>
          </HeaderText>
        </PageHeader>

        <Paper fill>
          <Container>
            <TeamsContainer>
              {data?.teams.map((team, i) => (
                <TeamBlock key={i}>
                  <TeamIdentifier>
                    Equipe {String(i + 1).padStart(2, '0')}
                    <TeamFrequency>{' | '}{team.frequency}</TeamFrequency>
                  </TeamIdentifier>

                  <TeamTitle color="#1a7709">{team.name}</TeamTitle>

                  <TeamList>
                    {team.team.map((member, i) => (
                      <Fragment key={i}>
                        <TeamItemName>{member.name}</TeamItemName>
                        <TeamItemCongregation>{member.congregation}</TeamItemCongregation>
                      </Fragment>
                    ))}
                  </TeamList>
                </TeamBlock>
              ))}

              {data?.extra && (
                <TeamBlock>
                  {data.extra.map((team, i) => (
                    <Fragment key={i}>
                      <TeamIdentifier>&nbsp;</TeamIdentifier>
                      <ExtraTitle color="#1a7709">{team.name}</ExtraTitle>

                      <TeamList>
                        {team.team.map((member, i) => (
                          <Fragment key={i}>
                            <ExtraItemName>{member}</ExtraItemName>
                          </Fragment>
                        ))}
                      </TeamList>
                    </Fragment>
                  ))}
                </TeamBlock>
              )}
            </TeamsContainer>
          </Container>
        </Paper>
      </Box>
    </Page>
  )
}
