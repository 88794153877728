/* eslint-disable jsx-a11y/label-has-associated-control */
import { FormEvent, MouseEvent, useState } from 'react'

import { compareAsc, differenceInWeeks, format, isFriday, isMonday, isSaturday, isSunday, isThursday, isTuesday, isWednesday, nextFriday, nextMonday, nextSaturday, nextSunday, nextThursday, nextTuesday, nextWednesday, previousDay, subDays } from 'date-fns'
import { maxBy, minBy } from 'lodash-es'

import { Day, MIDWEEK_DAY, WEEKEND_DAY } from '../../data/config'
import groups from '../../data/grupos.json'
import { CleaningDate } from '../CleaningBox/types'
import { CopyButton, DateInput, FloatingBox, Form, ResultBox, Row, SubmitButton, Title } from './styles'

export function GenerateCleaning() {
  const [resultJson, setResultJson] = useState('')

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const initialDateInput = e.currentTarget.elements.namedItem('initialDate') as HTMLInputElement
    const initialGroupInput = e.currentTarget.elements.namedItem('initialGroup') as HTMLInputElement

    const initialDateValue = initialDateInput.value
    const initialDate = new Date(`${initialDateValue}T00:00:00`)
    const initialGroup = Number(initialGroupInput.value)

    const schedule = generateCleaningSchedule(initialDate, initialGroup)

    const jsonResult = JSON.stringify(schedule.map(entry => ({
      date: format(entry.date, 'yyyy-MM-dd'),
      isGeneral: entry.isGeneral,
      groups: entry.groups,
    })), null, 2)

    setResultJson(jsonResult.replace(/\[\n\s*([^{\s])/g, '[$1').replace(/([^}\s])\n\s*\]/g, '$1]'))
  }

  function handleCopy(e: MouseEvent) {
    e.preventDefault()

    const { clipboard } = navigator

    clipboard.writeText(resultJson)
  }

  return (
    <FloatingBox>
      <Title>Gerador de Limpeza</Title>

      <Form onSubmit={handleSubmit} noValidate>
        <Row>
          <label>
            <strong>Grupo Inicial</strong>
            <DateInput
              id="initialGroup"
              type="number"
              min={minBy(groups.groups, 'number')?.number}
              max={maxBy(groups.groups, 'number')?.number}
              defaultValue={1}
            />
          </label>
        </Row>

        <Row>
          <label>
            <strong>Data inicial</strong>
            <DateInput id="initialDate" type="date" />
          </label>

          <SubmitButton type="submit">Gerar</SubmitButton>
        </Row>
      </Form>

      {!!resultJson && (
        <ResultBox>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: resultJson }} />
          <CopyButton type="button" onClick={handleCopy}>Copiar</CopyButton>
        </ResultBox>
      )}
    </FloatingBox>
  )
}

interface DateFN {
  is(date: Date | number): boolean
  next(date: Date | number): Date
}

const functions: Record<Day, DateFN> = {
  [Day.Sunday]: {
    is: isSunday,
    next: nextSunday,
  },
  [Day.Monday]: {
    is: isMonday,
    next: nextMonday,
  },
  [Day.Tuesday]: {
    is: isTuesday,
    next: nextTuesday,
  },
  [Day.Wednesday]: {
    is: isWednesday,
    next: nextWednesday,
  },
  [Day.Thursday]: {
    is: isThursday,
    next: nextThursday,
  },
  [Day.Friday]: {
    is: isFriday,
    next: nextFriday,
  },
  [Day.Saturday]: {
    is: isSaturday,
    next: nextSaturday,
  },
}
const midweekFn = functions[MIDWEEK_DAY]
const weekendFn = functions[WEEKEND_DAY]

function generateCleaningSchedule(initialDate: Date, initialGroup: number): CleaningDate[] {
  function getNextDay(date: Date) {
    const midweek = midweekFn.next(date)
    const weekend = weekendFn.next(date)

    return compareAsc(midweek, weekend) > 0
      ? weekend
      : midweek
  }

  let nextDay: Date = initialDate
  if (!midweekFn.is(initialDate) && !weekendFn.is(initialDate))
    initialDate = nextDay = getNextDay(initialDate)
  if (!isMonday(initialDate))
    initialDate = previousDay(initialDate, Day.Monday)

  const schedule: CleaningDate[] = []
  let setGeneral = false

  while (schedule.length < 25) {
    const weekNumber = differenceInWeeks(nextDay, initialDate, { roundingMethod: '' })
    let currentDay = nextDay

    if (setGeneral && isSunday(currentDay))
      currentDay = subDays(nextDay, 1)

    const entry: CleaningDate = {
      date: currentDay,
      groups: [((weekNumber - 1 + initialGroup) % 4) + 1],
      isGeneral: setGeneral,
    }
    if (!setGeneral && weekendFn.is(nextDay) && nextDay.getMonth() % 2 === 1)
      setGeneral = true
    else {
      setGeneral = false
      nextDay = getNextDay(nextDay)
    }

    schedule.push(entry)
  }

  schedule.sort((a, b) => compareAsc(a.date, b.date) || (!b.isGeneral ? 1 : -1))

  return schedule
}
