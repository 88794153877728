import styled from 'styled-components/macro'
import { StringMappingType } from 'typescript'

interface DotDivisorProps {
  color?: StringMappingType
}

export const DotDivisor = styled.div<DotDivisorProps>`
  display: inline-block;
  margin: 0 0.5em;
  color: ${p => p.color ?? '#951426'};

  &:after {
    content: '•';
  }
`
