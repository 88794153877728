import styled, { css } from 'styled-components/macro'

import { DottedElement } from '../../components/DottedSpare/DotedSpare'

export const BibleTalkList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 0.75rem;
`

const emptyStar = '\\2727'
const filledStar = '\\2726'

export const BibleTalkItem = styled.li<{ total: number, index: number, full?: boolean }>`
  display: grid;
  grid-template-columns: auto auto;
  flex: 0 0 calc(50% - 0.5rem);

  ${p => p.full && css`
    flex: 1 0 100%;
    margin-bottom: 1rem;
  `}

  &:before {
    ${p => css`
      content: "${Array(p.total).fill(emptyStar).map((_, i) => i === p.index ? filledStar : _)}";
    `}
  }

  &:before {
    display: block;
    writing-mode: vertical-lr;
    text-orientation: upright;
    grid-row: 1 / 4;
    color: #c18626;
    font-size: 1.125em;
  }
`

export const BibleTalkRow = styled.div`
  font-size: 14px;
`

export const BibleTalkLabel = styled.strong`
  color: #c18626;
`

export const BibleTalkValue = styled.span`
  color: #000;
`

export const FixedTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
`

export const FixedTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  padding: 0.5rem 15px;
  margin: 0 -15px;

  &:nth-child(even) {
    background: #fafafa;
    --bg: #fafafa;
  }
`

export const FixedTableColumn = styled.div`
  display: flex;
  flex: 1 0;
`

export const FixedTableDate = styled.div`
  padding-right: 1rem;
  font-weight: 700;
`

export const FixedTableActor = styled.div`
  padding-left: 1rem;
`

export const FixedTablePlaceDefault = styled.div`
  flex: 1;
  font-style: italic;
  font-weight: 700;
  text-align: left;
`

export const FixedTablePlace = FixedTablePlaceDefault.withComponent(styled(DottedElement)`
  .content {
    padding-right: 1rem;
  }
`)

interface FixedTableTimeProps {
  isAfternoon?: boolean
}

export const FixedTableTime = styled(DottedElement)<FixedTableTimeProps>`
  flex: 1;
  font-weight: 100;
  text-align: right;

  .content {
    padding-left: 1rem;
  }

  ${p => p.isAfternoon && css`
    color: #c18626;
    font-weight: 600;
  `}
`

export const VariableTable = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-rows: repeat(8, 1fr);
  column-gap: 1rem;
  margin-bottom: -9px;
`

export const VariableTableRow = styled.div`
  display: flex;
  margin: 0 -15px;
  padding: 0.5em 15px;

  &:nth-child(even) {
    background: #fafafa;
    --bg: #fafafa;
  }
`

export const VariableTableDate = styled.div`
  padding-right: 0.5rem;
  font-weight: 700;
`

export const VariableTableActor = styled(DottedElement)<{ isSpecial?: boolean }>`
  flex: 1;
  text-align: right;

  .content {
    padding-left: 0.5rem;

    ${p => p.isSpecial && css`
      font-weight: 600;
    `}
  }
`
