import styled from 'styled-components/macro'

import { DottedElement } from '../DottedSpare/DotedSpare'
import { GroupItemRole } from '../GroupItemRole/GroupItemRole'

export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 0.5rem;
  padding: 0 8px;
`

export const ItemRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto 45px;
  margin-bottom: 0.25rem;
  font-size: 15px;
`

export const ItemLabel = styled(DottedElement)`
  font-weight: 400;

  .content {
    color: #ed8739;
  }
`

export const ItemValue = styled.span`
  text-align: right;

  ${GroupItemRole} {
    margin: 0 0.25em 0 0;
    font-size: 1.125em;
    vertical-align: -0.125em;
  }
`
