import { useEffect } from 'react'

export function usePrintFilename(filename: string) {
  useEffect(() => {
    let currentDocTitle: string = document.title
    function onBeforePrint() {
      currentDocTitle = document.title
      document.title = filename

      window.addEventListener('focus', onWindowFocus)
    }

    function onWindowFocus() {
      document.title = currentDocTitle

      window.removeEventListener('focus', onWindowFocus)
    }

    window.addEventListener('beforeprint', onBeforePrint, false)
    return () => {
      window.removeEventListener('beforeprint', onBeforePrint, false)
      window.removeEventListener('focus', onWindowFocus)
    }
  }, [filename])
}
