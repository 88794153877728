import { rgba } from 'polished'
import styled, { css } from 'styled-components/macro'

interface PageHeaderProps {
  bg: string
  smaller?: boolean
}

export const PageHeader = styled.div<PageHeaderProps>`
  display: flex;
  position: relative;
  z-index: -1;
  justify-content: space-between;
  margin: -1.5rem;
  padding: 2.5rem 3.5rem;
  color: #fff;
  text-align: right;
  background: url("${p => p.bg}") no-repeat center / cover;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
  }

  ${p => p.smaller && css`
    align-items: center;
    margin-top: -2rem;
    margin-bottom: -1rem;
    padding: 0 3.5rem;

    ${HeaderIcon} svg {
      font-size: 70px;
    }
  `}
`

interface HeaderIconProps {
  mergeWithPaper?: boolean
}

export const HeaderIcon = styled.div<HeaderIconProps>`
  svg {
    font-size: 120px;
    fill: #fff;
    filter: drop-shadow(0 0 6px rgba(0,0,0,.6));
  }

  ${p => p.mergeWithPaper && css`
    margin-top: -0.5rem;
    margin-bottom: -1.5rem;

    svg {
      font-size: 160px;
    }
  `}
`

export const HeaderText = styled.div`
  flex: 1 0 400px;
  margin: auto 0;
  text-shadow: 2px 2px 5px ${rgba('#000000', 0.35)};
`

interface HeaderTitleProps {
  big?: boolean
  small?: boolean
}

export const HeaderTitle = styled.h1<HeaderTitleProps>`
  margin: 0;
  margin-bottom: 0;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;

  small {
    display: inline-block;
    font-size: 45%;
  }

  ${p => p.big && css`
    margin-bottom: 2rem;
    font-size: 52px;
    line-height: 0.75;
  `}

  ${p => p.small && css`
    font-size: 36px;
  `}
`

export const HeaderDate = styled.h2`
  margin: 4px 0;
  font-size: 18px;
  line-height: 1.15;
`

export const HeaderYear = styled.em`
  padding-right: 0.175em;
  font-size: 19px;
  font-style: italic;
`
