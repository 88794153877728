import React, { useMemo } from 'react'

import { groupBy, sortBy } from 'lodash-es'

import { Box } from '../../components/Box/Box'
import { Page } from '../../components/Page/Page'
import { HeaderDate, HeaderIcon, HeaderText, HeaderTitle, HeaderYear, PageHeader } from '../../components/PageHeader/PageHeader'
import { Paper } from '../../components/Paper/Paper'
import { PaperTitle } from '../../components/Paper/PaperTitle'
import jsonData from '../../data/servico-ministerial.json'
import { usePrintFilename } from '../../hooks/usePrintFilename'
import { ReactComponent as ServicoMinisterialIcon } from '../../icons/servico-ministerial.svg'
import { mapApiResponse } from '../../utils/api-helper'
import { dateFormat, formatYearMonth } from '../../utils/date'
import { BibleTalkItem, BibleTalkLabel, BibleTalkList, BibleTalkRow, BibleTalkValue, FixedTable, FixedTableActor, FixedTableColumn, FixedTableDate, FixedTablePlace, FixedTablePlaceDefault, FixedTableRow, FixedTableTime, VariableTable, VariableTableActor, VariableTableDate, VariableTableRow } from './styles'
import { Data } from './types'

export function ServicoMinisterial() {
  const data = useMemo(() => mapApiResponse<Data>(jsonData as any), [])

  const months = useMemo(() => {
    const months = Object.keys(groupBy(sortBy(
      data?.weekend ?? [],
      week => dateFormat(week.date, 'yyyy-MM'),
    ), week => dateFormat(week.date, 'yyyy-MM')))

    const firstMonth = months.shift()
    if (!months.length)
      return [firstMonth] as string[]

    return [firstMonth, months.pop()] as string[]
  }, [data?.weekend])

  const years = useMemo(() => (
    Object.keys(groupBy(
      months,
      month => month.split('-')[0],
    ))
  ), [])

  usePrintFilename('servico-ministerial.pdf')

  return (
    <Page>
      <Box fill>
        <PageHeader bg="/header/servico-ministerial.jpg">
          <HeaderIcon>
            <ServicoMinisterialIcon />
          </HeaderIcon>
          <HeaderText>
            <HeaderTitle>Serviço Ministerial</HeaderTitle>
            <HeaderDate>
              {months.map(month => formatYearMonth(month)[1]).join(' - ')}<br />
              <HeaderYear>{years.join(' - ')}</HeaderYear>
            </HeaderDate>
          </HeaderText>
        </PageHeader>

        {data?.bibleTalk && (
          <Paper>
            <PaperTitle color="#c18626">
              Conversas sobre a Bíblia
              {data?.specialTheme && <small> - {data.specialTheme}</small>}
            </PaperTitle>

            <BibleTalkList>
              {React.Children.toArray(data?.bibleTalk.map((bibleTalk, i) => (
                <BibleTalkItem total={data.bibleTalk?.length ?? 0} index={i} full={bibleTalk.full}>
                  <BibleTalkRow>
                    <BibleTalkLabel>Pergunte: </BibleTalkLabel>
                    <BibleTalkValue>{bibleTalk.question}</BibleTalkValue>
                  </BibleTalkRow>
                  {bibleTalk.text && (
                    <BibleTalkRow>
                      <BibleTalkLabel>Leia: </BibleTalkLabel>
                      <BibleTalkValue>{bibleTalk.text}</BibleTalkValue>
                    </BibleTalkRow>
                  )}
                  {bibleTalk.interest && (
                    <BibleTalkRow>
                      <BibleTalkLabel>Se o morador mostrar interesse: </BibleTalkLabel>
                      <BibleTalkValue>{bibleTalk.interest}</BibleTalkValue>
                    </BibleTalkRow>
                  )}
                  <BibleTalkRow>
                    <BibleTalkLabel>Deixa esta pergunta: </BibleTalkLabel>
                    <BibleTalkValue>{bibleTalk.nextQuestion}</BibleTalkValue>
                  </BibleTalkRow>
                </BibleTalkItem>
              )))}
            </BibleTalkList>
          </Paper>
        )}

        <Paper>
          <PaperTitle color="#c18626">
            Segunda a Sexta <small>- Arranjo Fixo</small>
          </PaperTitle>

          <FixedTable>
            {React.Children.toArray(data?.weekdays.map(weekday => {
              const [hour] = weekday.time.split('h')
              const PlaceComponent = weekday.actor
                ? FixedTablePlace
                : FixedTablePlaceDefault
              return (
                <FixedTableRow>
                  <FixedTableColumn>
                    <FixedTableDate>{weekday.weekday}</FixedTableDate>
                    <FixedTableTime isAfternoon={parseInt(hour) > 12}>{weekday.time}</FixedTableTime>
                  </FixedTableColumn>
                  <FixedTableColumn>
                    <PlaceComponent>{weekday.place}</PlaceComponent>
                    <FixedTableActor>{weekday.actor}</FixedTableActor>
                  </FixedTableColumn>
                </FixedTableRow>
              )
            }))}
          </FixedTable>
        </Paper>

        <Paper>
          <PaperTitle color="#c18626">
            Sábado <small>({data?.weekendTimes.Saturday})</small> e Domingo <small>({data?.weekendTimes.Sunday})</small>
          </PaperTitle>

          <VariableTable>
            {React.Children.toArray(data?.weekend.map(weekend => (
              <VariableTableRow>
                <VariableTableDate>{dateFormat(weekend.date, 'dd/MM')}</VariableTableDate>
                <VariableTableActor isSpecial={weekend.special}>{weekend.actor}</VariableTableActor>
              </VariableTableRow>
            )))}
          </VariableTable>
        </Paper>
      </Box>
    </Page>
  )
}
