import React, { useMemo } from 'react'

import { parse as parseDate, isWeekend } from 'date-fns'
import { capitalize, padStart } from 'lodash-es'

import { ReactComponent as LimpezaIcon } from '../../icons/limpeza.svg'
import { dateFormat } from '../../utils/date'
import { CleaningTable, CleaningTableDate, CleaningTableDay, CleaningTableGroupName, CleaningTableHeader, CleaningTableMonth, CleaningTitle, Container } from './styles'
import { CleaningDate } from './types'

interface CleaningBoxProps {
  data: CleaningDate[]
}

export function CleaningBox({ data }: CleaningBoxProps) {
  const datesBroupedByMonth = useMemo(() => data.reduce((months, date) => {
    const monthKey = dateFormat(date.date, 'yyyy-MM')
    const monthData: CleaningDate[] = months[monthKey] ?? []

    monthData.push(date)

    return {
      ...months,
      [monthKey]: monthData,
    }
  }, {} as Record<string, CleaningDate[]>), [data])

  return (
    <Container>
      <CleaningTitle>
        <LimpezaIcon />
        Limpeza
      </CleaningTitle>

      <CleaningTable>
        {Object.entries(datesBroupedByMonth).map(([monthKey, dates]) => {
          const monthDate = parseDate(`${monthKey}-01`, 'yyyy-MM-dd', new Date())

          return (
            <React.Fragment key={monthKey}>
              <CleaningTableHeader>{capitalize(dateFormat(monthDate, 'MMMM'))}</CleaningTableHeader>
              <CleaningTableMonth>
                {dates.map(date => (
                  <CleaningTableDay key={date.date.toISOString() + date.isGeneral} general={date.isGeneral} weekend={isWeekend(date.date)}>
                    <CleaningTableDate>{dateFormat(date.date, 'dd/MM \'(\'EEEEEE\')\'')}</CleaningTableDate>
                    <CleaningTableGroupName>
                      {date.isGeneral && 'Geral - '}
                      {date.specialOcasion
                        ? <em>{date.specialOcasion}</em>
                        : <>Grupo{date.groups.length > 1 && 's'} {date.groups.map(grp => padStart(grp.toString(), 2, '0')).join(' e ')}</>}
                    </CleaningTableGroupName>
                  </CleaningTableDay>
                ))}
              </CleaningTableMonth>

            </React.Fragment>
          )
        })}
      </CleaningTable>
    </Container>
  )
}
